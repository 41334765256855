import React, { Fragment } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import Footer from '@components/Footer';
import { has } from 'lodash';
import BackgroundImage from 'gatsby-background-image';
import FrontpageLink from '@components/FrontpageLink';
import { createUtmQuery } from '@lib/searchQuery';
import ResponsiveVideo from '@components/ui/ResponsiveVideo';
import PageTitle from '@components/ui/PageTitle';
import CtaButton from '@components/ui/CtaButton';
import CaseStudyHero from '@components/theme/CaseStudyHero';
import SharePage from '@components/theme/SharePage';
import ReadingArticle from '@components/theme/ReadingArticle';
import NavBar from '@components/theme/NavBar';

const utmMedium = 'case_studies';

export default function VideosTemplate({
  data: {
    casestudy
  },
  pageContext: { language, refs, layouts, configuration, meta }
}) {
  return (
    <Fragment>

      <CaseStudyHero casestudy={casestudy}>
        <Header
          dark
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </CaseStudyHero>
      <ReadingArticle body={casestudy.caseStudy.body}>
        <SharePage meta={meta} post={casestudy} configuration={configuration} />
      </ReadingArticle>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Fragment>
  );
}

export const query = graphql`
  query($id: String) {
    casestudy: wpCaseStudy(id: {eq: $id}) {
      seo {
        focuskw
      }
      properties {
        slug
        title
      }
      caseStudy {
        body
        description
        venueCategory
        venueName
        venueType
        venueLocation
        excerpt
        logo {
          localFile {
            sharp: childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        image {
          localFile {
            sharp: childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;